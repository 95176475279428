import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../auth';

export function Navbar() {
  const navigate = useNavigate();
  const { isAuthenticated, signOut } = useAuth();

  const items = isAuthenticated ? [
    {
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      command: () => navigate('/'),
    },
    {
      label: 'Accounts',
      icon: 'pi pi-fw pi-wallet',
      items: [
        {
          label: 'List accounts',
          icon: 'pi pi-fw pi-list',
          command: () => navigate('/accounts'),
        },
        {
          label: 'New account',
          icon: 'pi pi-fw pi-plus',
          command: () => navigate('/accounts/new'),
        },
      ],
    },
    {
      label: 'Categories',
      icon: 'pi pi-fw pi-tags',
      items: [
        {
          label: 'List categories',
          icon: 'pi pi-fw pi-list',
          command: () => navigate('/categories'),
        },
        {
          label: 'New category',
          icon: 'pi pi-fw pi-plus',
          command: () => navigate('/categories/new'),
        },
      ],
    },
    {
      label: 'Transactions',
      icon: 'pi pi-fw pi-money-bill',
      items: [
        {
          label: 'List transactions',
          icon: 'pi pi-fw pi-list',
          command: () => navigate('/transactions'),
        },
        {
          label: 'New transaction',
          icon: 'pi pi-fw pi-plus',
          command: () => navigate('/transactions/new'),
        },
        {
          label: 'Statistics',
          icon: 'pi pi-fw pi-chart-line',
          command: () => navigate('/transactions/statistics'),
        },
        {
          label: 'Import CSV',
          icon: 'pi pi-fw pi-upload',
          command: () => navigate('/transactions/import'),
        },
      ],
    },
    {
      label: 'Sign out',
      icon: 'pi pi-fw pi-power-off',
      command: () => {
        navigate('/');
        signOut();
      },
    },
  ] : [
    {
      label: 'Sign in',
      icon: 'pi pi-fw pi-sign-in',
      command: () => navigate('/signin'),
    },
    {
      label: 'Sign up',
      icon: 'pi pi-fw pi-user-plus',
      command: () => navigate('/signup'),
    },
  ];

  return (
    <Menubar model={items} />
  );
}
