import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { GuestRoute } from './auth/guest-route';
import { PrivateRoute } from './auth/private-route';
import { AccountsLayout } from './pages/accounts';
import { CategoriesLayout } from './pages/categories';
import { Layout } from './pages/layout';
import { TransactionsLayout } from './pages/transactions';

const HomePage = lazy(() => import('./pages/home'));
const SignInPage = lazy(() => import('./pages/sign-in'));
const SignUpPage = lazy(() => import('./pages/sign-up'));
const EmailConfirmationPage = lazy(() => import('./pages/email-confirmation'));
const AccountsPage = lazy(() => import('./pages/accounts/list-page'));
const EditAccountPage = lazy(() => import('./pages/accounts/edit-page'));
const NewAccountPage = lazy(() => import('./pages/accounts/new-page'));
const CategoriesPage = lazy(() => import('./pages/categories/list-page'));
const EditCategoryPage = lazy(() => import('./pages/categories/edit-page'));
const NewCategoryPage = lazy(() => import('./pages/categories/new-page'));
const TransactionsPage = lazy(() => import('./pages/transactions/list-page'));
const EditTransactionPage = lazy(() => import('./pages/transactions/edit-page'));
const NewTransactionPage = lazy(() => import('./pages/transactions/new-page'));
const StatisticsPage = lazy(() => import('./pages/transactions/statistics-page'));
const ImportPage = lazy(() => import('./pages/import'));
const ImportedTransactionsPage = lazy(() => import('./pages/imported-transactions'));

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<GuestRoute><SignInPage /></GuestRoute>} />
          <Route path="/signup" element={<GuestRoute><SignUpPage /></GuestRoute>} />
          <Route path="/confirm-email" element={<GuestRoute><EmailConfirmationPage /></GuestRoute>} />
          <Route path="/accounts" element={<PrivateRoute><AccountsLayout /></PrivateRoute>}>
            <Route index element={<AccountsPage />} />
            <Route path="new" element={<NewAccountPage />} />
            <Route path=":id/edit" element={<EditAccountPage />} />
          </Route>
          <Route path="/categories" element={<PrivateRoute><CategoriesLayout /></PrivateRoute>}>
            <Route index element={<CategoriesPage />} />
            <Route path="new" element={<NewCategoryPage />} />
            <Route path=":id/edit" element={<EditCategoryPage />} />
          </Route>
          <Route path="transactions" element={<PrivateRoute><TransactionsLayout /></PrivateRoute>}>
            <Route index element={<TransactionsPage />} />
            <Route path="new" element={<NewTransactionPage />} />
            <Route path="import" element={<ImportPage />} />
            <Route path="imports/:id" element={<ImportedTransactionsPage />} />
            <Route path="statistics" element={<StatisticsPage />} />
            <Route path=":id/edit" element={<EditTransactionPage />} />
          </Route>
          <Route path="*" element={<p>Not found</p>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
