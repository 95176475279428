import { isAxiosError } from 'axios';
import { Nullable } from 'primereact/ts-helpers';

import { ApiError, ApiErrorResponse } from '../types/api';

interface ErrorData {
  validation?: ApiError
  auth?: ApiError
  captcha?: ApiError
  unknown: boolean
}

export const parseError = (error: Nullable<Error>): ErrorData => {
  if (!error) {
    return { unknown: false };
  }

  if (isAxiosError<ApiErrorResponse>(error)) {
    const errorData = error.response?.data;
    if (!errorData) {
      return { unknown: false };
    }

    if (error.response?.status === 422) {
      return { unknown: false, validation: errorData.error };
    }

    if (error.response?.status === 401) {
      return { unknown: false, auth: errorData.error };
    }

    if (error.response?.status === 400 && errorData.error.key === 'captcha_verification_failed') {
      return { unknown: false, captcha: errorData.error };
    }
  }

  return { unknown: true };
};
