import { createContext } from 'react';

interface ContextProps {
  showInfoToast: (message: string) => void
  showSuccessToast: (message: string) => void
  showWarningToast: (message: string) => void
  showErrorToast: (message: string) => void
}

export const Context = createContext<ContextProps>({
  showInfoToast: () => {},
  showSuccessToast: () => {},
  showWarningToast: () => {},
  showErrorToast: () => {},
});
