import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { Banner } from '../components/banner';
import { Loader } from '../components/loader';
import { Navbar } from '../components/navbar';

export function Layout() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Suspense fallback={<Loader />}>
          <ErrorBoundary fallback={<Banner text="Something went wrong. Please reload the page" />}>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </main>
      <footer>
        <p className="text-center">Created by Vitali Semianiuk &copy; 2023-2024</p>
      </footer>
    </>
  );
}
