import { Message } from 'primereact/message';

interface BannerProps {
  text: string;
  severity?: 'info' | 'error';
}

export const Banner: React.FC<BannerProps> = ({ text, severity = 'error' }) => {
  return (
    <div className="flex justify-content-center align-items-center h-30rem">
      <Message severity={severity} text={text} />
    </div>
  );
};
