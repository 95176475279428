import { Toast, ToastMessage } from 'primereact/toast';
import { useRef } from 'react';

import { Context } from './context';

interface ProviderProps {
  children: React.ReactNode
}

export const Provider: React.FC<ProviderProps> = ({ children }) => {
  const toast = useRef<Toast>(null);

  const showToast = (severity: ToastMessage['severity'], summary: string, message: string) => {
    toast.current?.show({ severity, summary, detail: message });
  };

  const showInfoToast = (message: string) => {
    showToast('info', 'Info', message);
  };

  const showSuccessToast = (message: string) => {
    showToast('success', 'Success', message);
  };

  const showWarningToast = (message: string) => {
    showToast('warn', 'Warning', message);
  };

  const showErrorToast = (message: string) => {
    showToast('error', 'Error', message);
  };

  const value = {
    showInfoToast, showSuccessToast, showWarningToast, showErrorToast,
  };

  return (
    <Context.Provider value={value}>
      <Toast ref={toast} />
      {children}
    </Context.Provider>
  );
};
