import { useQueryClient } from '@tanstack/react-query';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Nullable } from 'primereact/ts-helpers';
import { PropsWithChildren } from 'react';

import { AuthContext } from './context';

export const Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useLocalStorage<Nullable<string>>('auth_token', null);
  const isAuthenticated = Boolean(token);
  const queryClient = useQueryClient();

  const signOut = () => {
    setToken(null);
    queryClient.clear();
  };

  const value = {
    token, isAuthenticated, setToken, signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
