import {
  MutationCache, QueryCache, QueryClient, QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';

import { App } from './app';
import { AuthProvider } from './auth';
import { useToast } from './components/toast';
import { parseError } from './utils';

export function Root() {
  const { showErrorToast } = useToast();
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        showErrorToast(error.message);
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, _variables, _context, mutation) => {
        const parsedError = parseError(error);
        if (mutation.options.onError && !parsedError.unknown) return;

        if (parsedError.validation) {
          showErrorToast(parsedError.validation.message);
        } else if (parsedError.auth) {
          showErrorToast(parsedError.auth.message);
        } else if (parsedError.captcha) {
          showErrorToast(parsedError.captcha.message);
        } else {
          showErrorToast(error.message);
        }
      },
    }),
  }));

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
      <ReactQueryDevtools buttonPosition='bottom-left' />
    </QueryClientProvider>
  );
}
